<script lang="ts" setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import appStore from "~/store";

const currentNavigation = ref(undefined);

const defaultDashboards = computed(() => {
  return [
    {
      ref: "manageOrganizations",
      name: "Organizations",
      singleWidget: {
        type: "manageOrganizations",
      },
    },
    {
      ref: "users",
      name: "Users",
      singleWidget: {
        type: "manageUsers",
      },
    },
    {
      ref: "extensions",
      name: "Extension Packs",
      singleWidget: {
        type: "manageExtensionPacks",
      },
    },
    {
      ref: "productGroups",
      name: "Product Groups",
      singleWidget: {
        type: "manageProductGroups",
      },
    },
    {
      ref: "product",
      name: "Products",
      singleWidget: {
        type: "manageProduct",
      },
    },
    {
      ref: "cms",
      name: "Home Content",
      singleWidget: {
        type: "manageContent",
      },
    },
  ] as any[];
});

const orderedDashboards = computed(() => {
  return defaultDashboards.value;
});

watch(orderedDashboards, () => {
  // if the current navigation isn't in the ordered dashboards then make it undefined
  if (orderedDashboards.value && orderedDashboards.value.length > 0 && currentNavigation.value) {
    const found = orderedDashboards.value.find(dashboard => dashboard.ref === currentNavigation.value.ref);
    if (!found) {
      currentNavigation.value = undefined;
    }
  }

  if (orderedDashboards.value && orderedDashboards.value.length > 0 && currentNavigation.value === undefined) {
    currentNavigation.value = orderedDashboards.value[0];
  }
}, {
  immediate: true,
  deep: true,
});

const {user, platformAdmin} = storeToRefs(appStore.userStore);
</script>

<template>
  <div class="bg-white">
    <header>
      <div>
        <header>

          <div class="border-b border-gray-200 dark:border-gray-700">
            <ul class="-mb-px ml-2 flex flex-wrap text-center text-sm font-medium text-gray-500 dark:text-gray-400">
              <li
                v-for="(item, idx) in orderedDashboards" :key="item.ref"
                class="mr-2"
                @click="currentNavigation = item"
              >
                <a
                  :id="item.ref"
                  :class="item.ref === currentNavigation?.ref ? 'inline-flex items-center justify-center p-4 text-theme-primary border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group' : 'inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'"
                  class="text-md"
                >
                  <MaterialDesignIcon
                    v-if="item?.icon" :name="item.icon" class="text-theme-primary mr-3"
                    size="18"
                  />
                  {{ item?.name }}
                </a>
              </li>
            </ul>
          </div>
        </header>
      </div>
    </header>
    <div>
      <KodexaDashboard
        v-if="currentNavigation"
        :allow-delete="false"
        :model-value="currentNavigation"
        :setup-mode="false"
      />
    </div>
  </div>
</template>
