<script setup lang="ts">
import type { PropType, Ref } from "vue";
import type { Taxon, TaxonFeatures, TaxonGuideProperties, Taxonomy } from "~/model";
import { Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { ColorPicker } from "@progress/kendo-vue-inputs";
import { notify } from "notiwind";
import { storeToRefs } from "pinia";
import { suggestTaxon } from "~/api/taxonomies/taxonomies";
import appStore from "~/store";
import { log } from "~/utils/logger";

const props = defineProps({
  modelValue: {
    type: Object as PropType<Taxon>,
    required: true,
  },
  taxonomy: {
    type: Object as PropType<Taxonomy>,
    required: true,
  },
  defaultTab: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(["update:modelValue", "delete"]);

const currentNavigation = ref(undefined);

const tabs = computed(() => {
  const base = [
    {
      name: "Overview",
      ref: "overview",
      icon: "info",
    },
  ];

  if (props.modelValue?.taxonType !== "SECTION") {
    let title = "Prompt";
    if (props.modelValue?.valuePath === "FORMULA") {
      title = "Formula";
    }
    if (props.modelValue?.valuePath === "REVIEW") {
      title = "Review";
    }
    if (props.modelValue?.valuePath === "EXTERNAL") {
      title = "External";
    }
    if (props.modelValue?.valuePath === "EXPRESSION") {
      title = "Expression";
    }

    base.push({
      name: title,
      ref: "semantics",
      icon: "ignore",
    });
  }

  if (props.modelValue?.group) {
    base.push({
      name: "Classification",
      ref: "additionalContext",
      icon: "ignore",
    });
    base.push({
      name: "Guidance",
      ref: "guidance",
      icon: "ignore",
    });
  }

  if (props.modelValue?.taxonType !== "SECTION" && !props.modelValue?.group) {
    base.push({
      name: "Guidance",
      ref: "guidance",
      icon: "ignore",
    });
    base.push({
      name: "Formatting",
      ref: "conditionalFormatting",
      icon: "ignore",
    });
  }

  if (props.modelValue?.taxonType !== "SECTION") {
    base.push({
      name: "Validations",
      ref: "validations",
      icon: "ignore",
    });
  }

  base.push({
    name: "Features",
    ref: "features",
    icon: "ignore",
  });

  base.push({
    name: "Properties",
    ref: "properties",
    icon: "ignore",
  });

  base.push({
    name: "Normalization",
    ref: "normalization",
    icon: "ignore",
  });

  if (props.modelValue?.taxonType === "SELECTION") {
    base.push({
      name: "Values",
      ref: "selection",
      icon: "ignore",
    });
  } else {
    if (currentNavigation.value?.ref === "selection") {
      currentNavigation.value = base[0];
    }
  }

  if (currentNavigation.value === undefined) {
    currentNavigation.value = base[props.defaultTab || 0];
  }

  return base;
});

const typeFeatures = ref({});

const guideProperties = ref({});

const localTaxon: Ref<Taxon | undefined> = computed(() => {
  if (props.modelValue?.typeFeatures) {
    typeFeatures.value = props.modelValue.typeFeatures || {};
  }
  if (props.modelValue?.guideProperties) {
    guideProperties.value = props.modelValue.guideProperties || {};
  } else {
    guideProperties.value = { guidanceKey: false } as TaxonGuideProperties;
  }
  return props.modelValue;
});

watch(typeFeatures, () => {
  if (localTaxon.value) {
    localTaxon.value.typeFeatures = typeFeatures.value;
  }
}, { deep: true });

watch(guideProperties, () => {
  if (localTaxon.value) {
    console.log("Setting guideProperties", guideProperties.value);
    localTaxon.value.guideProperties = guideProperties.value;
  }
}, { deep: true });

watch(localTaxon, () => {
  log.info("Local taxon changed");

  if (!localTaxon.value?.label) {
    localTaxon.value.label = "New Data Element";
  }

  emit("update:modelValue", localTaxon.value);
}, { deep: true });

const valuePaths = computed(() => {
  return [
    {
      id: "VALUE_OR_ALL_CONTENT",
      label: "Document",
    },
    {
      id: "METADATA",
      label: "Metadata",
    },
    {
      id: "DERIVED",
      label: "Derived",
    },
    {
      id: "FORMULA",
      label: "Formula",
    },
    {
      id: "EXPRESSION",
      label: "Expression",
    },
    {
      id: "REVIEW",
      label: "Review",
    },
    {
      id: "EXTERNAL",
      label: "External",
    },
  ];
});

// Taxon Types can be one of STRING, DATE, DATE_TIME, NUMBER, BOOLEAN, CURRENCY, URL, EMAIL_ADDRESS, PHONE_NUMBER, SELECTION

const taxonTypes = [
  {
    id: "STRING",
    label: "String",
  },
  {
    id: "DATE",
    label: "Date",
  },
  {
    id: "DATE_TIME",
    label: "Date Time",
  },
  {
    id: "NUMBER",
    label: "Number",
  },
  {
    id: "BOOLEAN",
    label: "Boolean",
  },
  {
    id: "CURRENCY",
    label: "Currency",
  },
  {
    id: "URL",
    label: "URL",
  },
  {
    id: "EMAIL_ADDRESS",
    label: "Email Address",
  },
  {
    id: "PHONE_NUMBER",
    label: "Phone Number",
  },
  {
    id: "PERCENTAGE",
    label: "Percentage",
  },
  {
    id: "SELECTION",
    label: "Selection",
  },
  {
    id: "SECTION",
    label: "Element Grouping",
  },
];

const metadataTypes = [{ id: "FILENAME", name: "Filename" }, {
  id: "TRANSACTION_UUID",
  name: "Transaction UUID",
}, { id: "CREATED_DATETIME", name: "Created Datetime" }, {
  id: "DOCUMENT_LABELS",
  name: "Document Labels",
}, { id: "OWNER_NAME", name: "Document Owner" }, { id: "DOCUMENT_STATUS", name: "Document Status" }, { id: "PAGE_NUMBER", name: "Page Number" }];

const deleteTaxon = function () {
  emit("delete");
};

watch(() => localTaxon.value?.label, (newValue) => {
  if (newValue && localTaxon.value) {
    // We want to replace the externalName with the label, removing any spaces and special characters
    localTaxon.value.externalName = newValue.replace(/[^a-z0-9]/gi, "");
  }
});

const supportsLongText = computed(() => {
  const notSupported = ["SECTION", "DATE", "DATE_TIME", "NUMBER", "BOOLEAN", "CURRENCY", "URL", "EMAIL_ADDRESS", "PHONE_NUMBER", "PERCENTAGE"];
  return localTaxon.value && localTaxon.value.taxonType && !notSupported.includes(localTaxon.value.taxonType) && !localTaxon.value.group;
});

const groupExternal = computed({
  get: () => localTaxon.value?.valuePath === "EXTERNAL",
  set: (value) => {
    if (localTaxon.value) {
      localTaxon.value.valuePath = value ? "EXTERNAL" : "VALUE_OR_ALL_CONTENT";
    }
  },
});

const { modelMetadata } = storeToRefs(appStore.projectStore);

const additionalTaxonFeatures = computed(() => {
  // We need to load up all the additional taxon features
  // from the models and assistant definitions
  const finalFeatures = [] as TaxonFeatures[];
  Array.from(modelMetadata.value.values()).forEach((modelMetadata) => {
    modelMetadata?.taxonFeatures?.forEach((taxonFeature) => {
      if (localTaxon.value?.group && taxonFeature.groupOnly) {
        finalFeatures.push(taxonFeature);
      } else if (!localTaxon.value?.group && !taxonFeature.groupOnly) {
        finalFeatures.push(taxonFeature);
      }
    });
  });

  return finalFeatures;
});

const suggesting = ref(false);

function extractGroovyCode(response: string): string | null {
  const groovyRegex = /```groovy([\s\S]*?)```/;
  const match = response.match(groovyRegex);

  if (match) {
    return match[1].trim(); // Return the Groovy code block without the ```groovy markers
  } else {
    return null; // No Groovy code block found
  }
}

async function suggest() {
  if (localTaxon.value) {
    suggesting.value = true;
    try {
      const { contentTaxonomies } = storeToRefs(appStore.projectStore);

      const taxonomies = {};

      contentTaxonomies.value.forEach((taxonomy) => {
        taxonomies[taxonomy.name] = taxonomy;
      });

      const suggestionResponse = await suggestTaxon({ taxonomies, taxon: localTaxon.value });

      log.info(`Suggestion prompt: ${suggestionResponse.prompt}`);

      const suggestion = suggestionResponse.suggestion;

      suggesting.value = false;

      log.info(`Suggested ${suggestion}`);
      if (suggestion) {
        if (localTaxon.value.valuePath === "FORMULA") {
          localTaxon.value.semanticDefinition = suggestion;
        } else if (localTaxon.value.valuePath === "REVIEW") {
          localTaxon.value.semanticDefinition = suggestion;
        } else if (localTaxon.value.valuePath === "VALUE_ONLY" || localTaxon.value.valuePath === "VALUE_OR_ALL_CONTENT") {
          localTaxon.value.semanticDefinition = suggestion;
        } else {
          localTaxon.value.expression = extractGroovyCode(suggestion) || suggestion;
        }
      }
    } catch (e) {
      notify({
        group: "error",
        title: "Unable to suggest at this time",
        text: "We were unable to suggest a semantic definition for this data element",
      }, 5000);
      suggesting.value = false;
    }
  }
}

const items = [
  { id: "string", text: "String" },
  { id: "number", text: "Number" },
  { id: "boolean", text: "Boolean" },
];

function addOption() {
  if (!localTaxon.value) {
    localTaxon.value.options = [];
  }

  localTaxon.value?.options?.push({ name: "", label: "", type: "string", description: "" });
}

function deleteOption(idx: number) {
  localTaxon.value?.options?.splice(idx, 1);
}

function addValue(option: any) {
  option.values.push({ label: "", value: "" });
}

function removeValue(option: any, index: number) {
  option.values.splice(index, 1);
}

function addAdditionalContext() {
  localTaxon.value.additionContexts = localTaxon.value.additionContexts || [];
  localTaxon.value.additionContexts.push({ context: "" });
}

function removeAdditionalContext(idx: number) {
  localTaxon.value.additionContexts = localTaxon.value.additionContexts || [];
  localTaxon.value.additionContexts.splice(idx, 1);
}

function addLexicalRelation() {
  localTaxon.value.lexicalRelations = localTaxon.value.lexicalRelations || [];
  localTaxon.value.lexicalRelations.push({ value: "" });
}

function removeLexicalRelation(idx) {
  localTaxon.value.lexicalRelations = localTaxon.value.lexicalRelations || [];
  localTaxon.value.lexicalRelations.splice(idx, 1);
}

// Make sure we clear out the semantic definition and expression when the value path changes
watch(() => localTaxon.value?.valuePath, (newValue, oldValue) => {
  if (oldValue !== newValue) {
    if (localTaxon.value) {
      localTaxon.value.semanticDefinition = "";
      localTaxon.value.expression = "";
    }
  }
});
</script>

<template>
  <div>
    <div class="mt-2 border-b border-gray-200 dark:border-gray-700">
      <div class="overflow-x-auto">
        <ul v-if="localTaxon" class="-mb-px ml-2 flex text-center text-sm font-medium text-gray-500 dark:text-gray-400">
          <li
            v-for="(item) in tabs" :key="item.ref"
            class="mr-2"
            @click="currentNavigation = item"
          >
            <a
              class="text-sm"
              :class="item.ref === currentNavigation?.ref ? 'inline-flex items-center justify-center p-2 text-theme-primary border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group' : 'inline-flex items-center justify-center p-2 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'"
            >
              <MaterialDesignIcon
                v-if="item?.icon" :name="item.icon" size="12"
                class="text-theme-primary mr-1"
              />
              {{ item?.name }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="currentNavigation.ref === 'overview'" class="mx-2 mt-2">
      <KodexaArticle article-id="9117975" text="Learn about data elements" :slide="false" />

      <KodexaTextInput id="taxonName" v-model="localTaxon.label" label="Name" type="text" name="name" />
      <KodexaTextArea v-model="localTaxon.description" label="Description" class="mt-2" name="description" :rows="10" />
      <KodexaButton
        v-if="modelValue.valuePath === 'FORMULA' || modelValue.valuePath === 'REVIEW' || modelValue.valuePath === 'EXTERNAL' || modelValue.valuePath === 'EXPRESSION' || modelValue.valuePath === 'VALUE_ONLY' || modelValue.valuePath === 'VALUE_OR_ALL_CONTENT'"
        size="small" type="secondary" class="mt-2" icon="creation-outline" :loading="suggesting" @click="suggest"
      >
        Generate {{
          modelValue.valuePath === 'FORMULA' ? 'Formula' : modelValue.valuePath === 'REVIEW' ? 'Review' : (modelValue.valuePath === 'VALUE_ONLY' || modelValue.valuePath === 'VALUE_OR_ALL_CONTENT') ? 'Prompt' : 'Expression'
        }}
      </KodexaButton>
      <KodexaCheckbox
        v-model="localTaxon.enabled"
        label="Enabled" name="group" class="mt-2"
        hint="This data element is enabled and can be used in the system."
      />
      <KodexaCheckbox
        v-if="localTaxon.taxonType !== 'SECTION'"
        v-model="localTaxon.group" :disabled="localTaxon.children && localTaxon.children.length > 0"
        label="Data Group" name="group" class="mt-2"
        hint="A group has no inherent data, it is a container for other data elements."
      />
      <ColorPicker
        v-if="localTaxon.taxonType !== 'SECTION' && !localTaxon.group && localTaxon.valuePath !== 'METADATA' && localTaxon.valuePath !== 'FORMULA'"
        v-model="localTaxon.color"
        title="Color" class="mt-2" view="gradient"
      />
      <KodexaDropDown
        v-if="!localTaxon.group"
        v-model="localTaxon.valuePath"
        class="mt-1"
        label="Source" name="valuePath" :items="valuePaths"
        hint="Where do you get the data from?"
        value-field="id" text-field="label"
      />
      <KodexaDropDown
        v-if="localTaxon.valuePath !== 'METADATA' && !localTaxon.group"
        v-model="localTaxon.taxonType" class="mt-2"
        label="Data Type" name="taxonType" :items="taxonTypes"
        value-field="id" text-field="label"
        :filterable="true"
      />

      <KodexaCheckbox
        v-if="localTaxon.group"
        id="groupExternal"
        v-model="groupExternal" label="Create using External Data"
        name="isExpression" class="mt-2"
      />

      <KodexaDropDown
        v-if="localTaxon.valuePath === 'METADATA' && !localTaxon.group"
        v-model="localTaxon.metadataValue" label="Metadata Value"
        class="mt-2"
        name="metadataValue"
        value-field="id" text-field="name"
        hint="Choose the metadata you wish to use"
        :items="metadataTypes"
      />
      <KodexaCheckbox
        v-if="supportsLongText" v-model="typeFeatures.longText"
        label="Long Text" name="longText" class="mt-2"
        hint="Present this information as a long text field"
      />
      <KodexaCheckbox
        v-if="supportsLongText" v-model="typeFeatures.markdown"
        label="Allow Markdown" name="longText" class="mt-2"
        hint="Use markdown to help with the formatting of the text"
      />
      <KodexaCheckbox
        v-if="supportsLongText" v-model="typeFeatures.summarize"
        label="Summarize" name="summarize" class="mt-2"
        hint="Summarize content before capture"
      />
      <KodexaCheckbox
        v-if="!localTaxon.group && !localTaxon.formula && localTaxon.taxonType !== 'SECTION'"
        v-model="typeFeatures.expected"
        label="Expected" name="expected" class="mt-2"
        hint="This data element is expected to be present"
      />
      <KodexaCheckbox
        v-model="localTaxon.generateName" label="Use Generated ID"
        name="generateName" class="mt-2"
      />
      <KodexaTextInput
        v-if="!localTaxon.generateName"
        id="taxonInternalID"
        v-model="localTaxon.name"
        label="Internal ID"
        type="text"
        class="mt-2"
        name="name"
        @input="localTaxon.name = $event.target.value.replace(/\s/g, '')"
      />
      <KodexaTextInput
        id="taxonExternalName" v-model="localTaxon.externalName" label="External Name"
        type="text" class="mt-2" name="externalName"
      />
      <KodexaButton size="small" type="danger" class="mt-8" icon="delete" @click="deleteTaxon">
        Delete Data Element
      </KodexaButton>
    </div>
    <div v-if="currentNavigation.ref === 'semantics'" class="mx-2 mt-2">
      <KodexaArticle
        v-if="modelValue.valuePath === 'FORMULA'" article-id="9117970" text="Learn about using a formula"
        :slide="false"
      />
      <KodexaArticle
        v-else-if="modelValue.valuePath === 'REVIEW'" article-id="9702349" text="Learn about using a review templates"
        :slide="false"
      />
      <KodexaArticle
        v-else-if="modelValue.valuePath === 'EXPRESSION'" article-id="10444631"
        text="Learn more about expressions"
        :slide="false"
      />
      <KodexaArticle
        v-else-if="modelValue.valuePath === 'EXTERNAL'" article-id="9702066" text="Learn more about external data"
        class="mt-1"
        :slide="false"
      />
      <KodexaArticle
        v-else article-id="9117973" text="Learn how you can use a prompt to improve data capture"
        :slide="false"
      />
      <KodexaCodeEditor
        v-if="localTaxon.valuePath === 'EXPRESSION'" v-model="localTaxon.expression"
        label="Expression" name="expression" :rows="20" style="height: calc(100vh - 400px)" language="groovy"
        :loading="suggesting"
      />
      <KodexaCodeEditor
        v-else-if="localTaxon.valuePath === 'EXTERNAL'" v-model="localTaxon.expression" language="groovy"
        label="External Data Expression" name="expression" :rows="20" style="height: calc(100vh - 400px)"
        :minimap="false"
        :loading="suggesting"
      />
      <FormulaInput
        v-else-if="modelValue.valuePath === 'FORMULA'"
        v-model="localTaxon.semanticDefinition"
        :loading="suggesting"
        :taxon="localTaxon"
        :taxonomy="taxonomy"
        :num-rows="18"
        :toggle-available-elements="false"
      />
      <KodexaCodeEditor
        v-else-if="modelValue.valuePath === 'REVIEW'"
        v-model="localTaxon.semanticDefinition"
        :loading="suggesting"
        language="jinja"
        style="height: calc(100vh - 400px)"
        name="semanticDefinition"
      />
      <KodexaTextArea
        v-else
        v-model="localTaxon.semanticDefinition"
        :loading="suggesting"
        :rows="18"
        name="semanticDefinition"
      />
    </div>
    <div v-if="currentNavigation.ref === 'additionalContext'" class="mx-2 mt-2">
      <div
        v-if="(localTaxon?.valuePath === 'VALUE_ONLY' || localTaxon.valuePath === 'VALUE_OR_ALL_CONTENT') || localTaxon.group"
        class="synonyms-section space-y-4"
      >
        <div class="mt-3">
          <p class="font-bold">
            Additional Context
          </p>
          <p>
            Additional context is used when we are using record-based chunking to help us understand how to break up
            content into chunks.
          </p>
        </div>

        <ul class="space-y-2">
          <li v-for="(additionContext, idx) in localTaxon?.additionContexts" :key="idx" class="space-y-2">
            <KodexaDropDown
              v-model="additionContext.type"
              name="contextType"
              :items="[
                { id: 'RECORD_DEFINITION', text: 'Record Definition' },
                { id: 'RECORD_SECTION_STARTER_MARKER', text: 'Record Section Starter Marker' },
                { id: 'RECORD_SECTION_END_MARKER', text: 'Record Section End Marker' },
                { id: 'RECORD_START_MARKER', text: 'Record Start Marker' },
                { id: 'RECORD_END_MARKER', text: 'Record End Marker' },
              ]"
              label="Context Type"
            />
            <div class="flex items-start space-x-2">
              <KodexaTextArea
                v-model="additionContext.context"
                class="flex-grow"
                :rows="6"
              />
              <KodexaButton
                type="danger"
                size="small"
                icon="delete"
                class="flex-shrink-0"
                @click="removeAdditionalContext(idx)"
              />
            </div>
          </li>
        </ul>

        <div class="space-y-2">
          <KodexaButton
            type="secondary"
            size="small"
            icon="plus"
            class="w-full sm:w-auto"
            @click="addAdditionalContext()"
          >
            Add Additional Context
          </KodexaButton>
        </div>
        <div class="mt-3">
          <p class="font-bold">
            Lexical Relations
          </p>
          <p>
            Lexical relations are used when we are using embeddings for classification and chunking, they provide a way
            to provide synonyms and antonyms which we can use to classify content areas.
          </p>
        </div>

        <ul class="space-y-2">
          <li v-for="(lexicalRelation, idx) in localTaxon.lexicalRelations" :key="idx" class="space-y-2">
            <div class="flex items-center justify-between">
              <KodexaDropDown
                v-model="lexicalRelation.type"
                name="relationType"
                :items="[
                  { id: 'SYNONYM', text: 'Synonym' },
                  { id: 'ANTONYM', text: 'Antonym' },
                ]"
                label="Relation Type"
              />
              <KodexaButton
                type="danger"
                size="small"
                icon="delete"
                class="mt-2"
                @click="removeLexicalRelation(idx)"
              />
            </div>
            <KodexaTextArea
              v-model="lexicalRelation.value"
              name="relationValue"
              class="w-full"
              :rows="3"
            />
          </li>
        </ul>

        <div class="space-y-2">
          <KodexaButton
            type="secondary"
            size="small"
            icon="plus"
            class="w-full sm:w-auto"
            @click="addLexicalRelation()"
          >
            Add Lexical Relation
          </KodexaButton>
        </div>
      </div>
    </div>
    <div v-if="currentNavigation.ref === 'features'" class="mx-2 mt-2">
      <!-- We will include the options from models that added them to taxons -->
      <div v-if="additionalTaxonFeatures">
        <div v-for="additionalTaxonFeature in additionalTaxonFeatures">
          <ConfigurationOption
            v-for="option in additionalTaxonFeature.options"
            :key="option.name"
            v-model="typeFeatures"
            :item="option"
          />
        </div>
      </div>
      <div v-else>
        <div class="mt-2 text-gray-600">
          Not additional features
        </div>
      </div>
    </div>
    <div v-if="currentNavigation.ref === 'guidance'" class="mx-2 mt-2">
      <KodexaArticle article-id="9911275" text="Learn about guidance" :slide="false" />
      <KodexaCheckbox
        v-if="!localTaxon.group"
        v-model="guideProperties.guidanceKey"
        label="Use data element as part of Guidance Key" name="guidance"
        hint="Guidance is a learning approach, and the use of a guidance key helps the system learn by understanding that behaviour will be related based on the value of this data element."
      />
      <KodexaCodeEditor
        v-if="localTaxon.group"
        v-model="guideProperties.guidanceKeyExpression"
        style="height: 200px; margin-bottom: 50px;"
        language="groovy"
        label="Guidance Key Expression" name="guidanceExpression" :rows="10"
        hint="Use an expression to group guidance based on metadata or other data elements."
      />
      <KodexaCheckbox
        v-if="localTaxon.group"
        v-model="guideProperties.useGuidanceForClassification"
        label="Use guidance for classification" name="useGuidanceForClassification" hint="Use the guidance key to help classify content."
      />
      <KodexaCheckbox
        v-if="!localTaxon.group"
        v-model="guideProperties.ifPresentRequired"
        class="mt-1"
        label="Required, if present" name="ifPresentRequired" hint="This data element is required if it is present in the guidance, and we will add a validation rule dynamically"
      />
    </div>
    <div v-if="currentNavigation.ref === 'normalization'" class="mx-2 mt-2">
      <KodexaArticle article-id="9117988" text="Learn about normalizing data" :slide="false" />

      <div v-if="localTaxon?.taxonType === 'DATE' || localTaxon?.taxonType === 'DATE_TIME'">
        <KodexaCheckbox
          v-model="typeFeatures.normalizeDate"
          label="Normalize Date" name="normalizeDate" class="mt-2"
          hint="Normalize the date to a standard format"
        />
        <KodexaTextInput
          v-if="typeFeatures.normalizeDate"
          v-model="typeFeatures.dateFormat"
          label="Date Format" name="dateFormat" class="mt-2"
          hint="Provide the format for date normalization (e.g., 'yyyy-MM-dd')"
        />
      </div>
      <div v-else-if="localTaxon?.taxonType === 'NUMBER'">
        <KodexaCheckbox
          v-model="typeFeatures.truncateDecimal"
          label="Truncate Decimal Places" name="truncateDecimal" class="mt-2"
          hint="Truncate the number to a fixed number of decimal places"
        />
        <KodexaTextInput
          v-if="typeFeatures.truncateDecimal"
          v-model="typeFeatures.decimalPlaces"
          label="Decimal Places" name="decimalPlaces" class="mt-2"
          type="number"
          hint="Specify the number of decimal places to truncate to"
        />
      </div>
      <div v-else-if="localTaxon?.taxonType === 'STRING' || localTaxon?.taxonType === 'SELECTION'">
        <KodexaCheckbox
          v-model="typeFeatures.overrideWidth"
          label="Override Display Width" name="overrideWidth" class="mt-2"
          hint="Override the default display width for this field"
        />
        <KodexaTextInput
          v-if="typeFeatures.overrideWidth"
          v-model="typeFeatures.displayWidth"
          label="Display Width" name="displayWidth" class="mt-2"
          type="number"
          hint="Specify the display width in pixels"
        />
      </div>

      <KodexaCheckbox
        v-model="localTaxon.enableFallbackExpression" label="Enable Fallback Expression"
        name="enableFallbackExpression" class="mt-2" hint="Use a fallback expression if the data element is not present"
      />

      <KodexaCodeEditor
        v-if="localTaxon.enableFallbackExpression" v-model="localTaxon.fallbackExpression" language="groovy"
        label="Fallback Expression" name="fallbackExpression" class="mt-2 h-80"
      />

      <KodexaCheckbox
        v-model="localTaxon.multiValue" label="Multivalue" name="multivalue" class="mt-2"
        hint="Allow the attribute to have multiple values in the same data object"
      />

      <KodexaCheckbox
        v-model="localTaxon.userEditable" label="User Editable" name="userEditable" class="mt-2"
        hint="Determine if we the user can change the value of the attribute in forms"
      />

      <KodexaCheckbox
        v-model="localTaxon.notUserLabelled" label="Not User Labelled" name="notUserLabelled"
        class="mt-2" hint="Determine whether to show the attribute as a label that can be added by a user"
      />
    </div>
    <div v-if="currentNavigation.ref === 'selection'" class="mx-2 mt-2">
      <SelectionOptions v-model="localTaxon.selectionOptions" />
    </div>
    <div v-if="currentNavigation.ref === 'conditionalFormatting'" class="mx-2 mt-2" style="height: calc(100vh - 200px)">
      <ConditionalFormatting
        v-model="localTaxon.conditionalFormats"
        :taxon="localTaxon"
        :taxonomy="taxonomy"
      />
    </div>
    <div v-if="currentNavigation.ref === 'validations'" class="mx-2 mt-2" style="height: calc(100vh - 200px)">
      <TaxonValidations
        v-model="localTaxon.validationRules"
        :taxon="localTaxon"
        :taxonomy="taxonomy"
      />
    </div>
    <div v-if="currentNavigation.ref === 'properties'" class="mx-2 mt-2">
      <Toolbar class="border-0">
        <ToolbarSpacer />
        <KodexaButton
          name="add" icon="plus"
          size="small"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" @click="addOption"
        >
          Add Option
        </KodexaButton>
      </Toolbar>
      <div class="p-4 space-y-4">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div v-for="(option, idx) in localTaxon.options" :key="idx" class="p-4 shadow-lg rounded-lg">
            <KodexaTextInput
              v-model="option.name"
              class="mt-2"
              name="name"
              label="Name"
            />
            <KodexaTextInput
              v-model="option.label"
              class="mt-2"
              name="label"
              label="Label"
            />
            <KodexaTextInput
              v-model="option.description"
              class="mt-2"
              name="description"
              label="Description"
            />
            <KodexaDropDown
              v-model="option.type"
              class="mt-2"
              name="type"
              :items="items"
              label="Type"
            />

            <div v-if="option.type === 'string'" class="mt-4">
              <h4 class="font-semibold mb-2">
                Possible Values
              </h4>
              <div v-for="(value, index) in values" :key="index" class="flex items-center mb-2">
                <KodexaTextInput
                  v-model="value.label"
                  class="mr-2"
                  name="label"
                  label="Label"
                />
                <KodexaTextInput
                  v-model="value.value"
                  class="mr-2"
                  name="value"
                  label="Value"
                />
                <KodexaButton
                  name="delete" icon="delete" type="danger" size="small"
                  @click="removeValue(option, index)"
                >
                  Delete
                </KodexaButton>
              </div>
              <KodexaButton name="add" icon="add" type="primary" size="small" @click="addValue(option)">
                Add Value
              </KodexaButton>
            </div>

            <KodexaButton
              name="delete" icon="delete" type="danger" class="mt-2" size="small"
              @click="deleteOption(idx)"
            >
              Delete
            </KodexaButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
