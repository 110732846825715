<script lang="ts" setup>
import type { SplitterPaneProps } from "@progress/kendo-vue-layout";
import type { PropType, Ref } from "vue";
import { Disclosure } from "@headlessui/vue";
import { Toolbar } from "@progress/kendo-vue-buttons";
import { Splitter } from "@progress/kendo-vue-layout";
import { notify } from "notiwind";
import { storeToRefs } from "pinia";
import { DndProvider } from "vue3-dnd";
import { assignNext } from "~/api/stores/stores";
import imageUrl from "~/images/kodexa-top.png";
import type { DataException, DataForm, DataObject, DocumentFamily } from "~/model";
import appStore from "~/store";
import type { KeyboardShortcut } from "~/store/useKeyboard";
import { useKeyboard } from "~/store/useKeyboard";
import { createSidecar } from "~/store/useSidecar";
import type { DataFormViewer, DocumentViewer } from "~/store/useWorkspace";
import { log } from "~/utils/logger";
import { RefHelper } from "~/utils/ref-utils";
import SidecarPanel from "~/views/app/organizations/project/workspace/panels/sidecar-panel.vue";

interface KioskOptions {
  workspaceId: string;
  dataFormRef: string;
  storeRef: string;
  storeId: string;
  filters?: string;
  isWidget?: boolean;
}

const props = defineProps({
  kioskOptions: {
    type: Object as PropType<KioskOptions>,
    required: false,
    default: () => {
      return {
        workspaceId: "",
        dataFormRef: "",
        storeRef: "",
        storeId: "",
        filters: "",
        isWidget: false,
      };
    },
  },
});

defineEmits(["updateTitle"]);
const pathFilter = ref("");
const currentPath = ref("");

const loading = ref(true);
const isButtonPressed = ref(false);
const previousDocumentIds: string[] = [];
const hasMoreDocuments = ref(true);
const filters = computed(() => {
  const { filters: searchFilters, storeId } = props.kioskOptions;
  const pathFilterExpression = pathFilter.value ? `path~'%${pathFilter.value}%'` : null;
  const extraFilters = [searchFilters, pathFilterExpression].filter(Boolean).join(" and ");
  return `store.id: '${storeId}'${extraFilters ? ` and ${extraFilters}` : ""}`;
});
/**
 * route / url
 * /a/o/:organizationId/p/:projectId/kiosks/:workspaceId/:dataFormRef/:storeId/:searchText
 * /kiosks/o/:organizationId/p/:projectId/:workspaceId/:dataFormRef/:storeId/:searchText
 *
 */

const initialized = ref(false);
let sidecarStore: any;

watch(() => props.kioskOptions, async () => {
  if (props.kioskOptions.workspaceId && props.kioskOptions.dataFormRef && props.kioskOptions.storeRef) {
    const workspaceId = props.kioskOptions.workspaceId as string;
    try {
      appStore.workspaceStore.loadWorkspace(workspaceId).then(() => {
        log.info("Loaded workspace");
        sidecarStore = createSidecar(workspaceId as string, false);
        initialized.value = true;
        log.info("Initialized");
      });
    } catch (error) {
      console.error("Error during initialization:", error);
    }
  } else {
    log.error("No workspaceId found");
  }
}, { immediate: true, deep: true });

const {
  dataForms,
  isLoaded,
  tagMetadataMap,
} = storeToRefs(appStore.projectStore);

const {
  currentWorkspaceId,
  sidecarPanelOpen,
} = storeToRefs(appStore.workspaceStore);

const keyboardStore = useKeyboard();

const allExceptions = ref([]) as Ref<DataException[]>;

// For the next demo we want to grab a document from the project, that has label "has_issues"
// and load it into the workspace and make it the current view

const saveShortcut = {
  key: "alt+s",
  altKey: "alt+ß",
  description: "Save the form data and open the next document",
  callback: () => {
    if (allExceptions.value.length === 0) {
      saveData();
    }
  },
} as KeyboardShortcut;

const rejectShortcut = {
  key: "alt+r",
  altKey: "alt+®",
  description: "Reject the document and open the next document",
  callback: () => {
    rejectData();
  },
} as KeyboardShortcut;

keyboardStore.addShortcut(rejectShortcut);
keyboardStore.addShortcut(saveShortcut);

const kioskView: Ref<undefined | DataFormViewer> = ref(undefined);

const promptMessage = computed(() => {
  if (pathFilter.value !== "") {
    return {
      notif: { message: "Document not available", title: "Document not available" },
      formMessage: "<span class=\"font-bold\">DOCUMENT NOT AVAILABLE</span>"
        + "<br/><br/>The document you selected is either "
        + "<br/>assigned to another user or has already been removed from the "
        + "<br/>document queue. Please refresh to load the next available document",
    };
  } else {
    return {
      notif: { message: "No more documents", title: "No more documents" },
      formMessage: "<span class=\"font-bold\">DOCUMENT NOT AVAILABLE</span>"
        + "<br/>There are no more documents to review.",
    };
  }
});

const panes: Ref<SplitterPaneProps[]> = ref([
  {
    size: undefined,
    min: "20%",
    collapsible: false,
    content: "form",
  },
  {
    size: "0%",
    min: "20%",
    collapsible: true,
    content: "sidecar",
  },
] as SplitterPaneProps[]);

function onSplitterChange(changeEvent: any) {
  if (changeEvent.newState) {
    panes.value = changeEvent.newState;
  }
}

const currentDataForm = computed(() => {
  return dataForms.value.find((df: DataForm) => df.ref === props.kioskOptions.dataFormRef);
});

/**
 * Locks the next document for review.
 *
 * @return A Promise that resolves to a DocumentFamily object if a document is successfully locked, or undefined if there are no more documents to review.
 */
async function lockNextDoc(): Promise<DocumentFamily | undefined> {
  loading.value = true;
  appStore.workspaceStore.clearWorkspaceData();

  try {
    const refHelper = new RefHelper(props.kioskOptions.storeRef as string);
    const documentFamily = await assignNext(refHelper.getOrgSlug(), refHelper.getSlug(), refHelper.getVersion(), {
      releaseInMinutes: 60,
      filter: filters.value,
    });
    appStore.workspaceStore.startTransaction();
    hasMoreDocuments.value = true;
    return documentFamily;
  } catch {
    notify({
      title: promptMessage.value.notif.title,
      message: promptMessage.value.notif.message,
      icon: "minusCircle",
      group: "error",
      duration: 2000,
    });
    loading.value = false;
    return undefined;
  }
}

// We use this flag to make sure we don't show the dialog twice
const noExceptionDialogShown = ref(false);

async function loadNextDoc() {
  loading.value = true;
  noExceptionDialogShown.value = false;
  log.info("Loading next document");
  try {
    const documentFamily = await lockNextDoc();

    if (!documentFamily) {
      // notify({
      //   title: promptMessage.value.notif.title,
      //   message: promptMessage.value.notif.message,
      //   icon: "minusCircle",
      //   group: "error",
      //   duration: 2000,
      // });

      currentPath.value = "";
      kioskView.value = undefined;
      loading.value = false;
      initialized.value = true;
      return;
    }

    const dataForm = dataForms.value.find((df: DataForm) => df.ref === props.kioskOptions.dataFormRef);

    currentPath.value = documentFamily.path;

    appStore.workspaceStore.clearDataForm();
    kioskView.value = appStore.workspaceStore.addDataForm(dataForm, documentFamily, true, true);

    log.info("Kiosk view loaded");
    loading.value = false;

    // We are going to try and make sure that the popup for approve doesn't appear instantly
    // this is to stop the initial state of a document ending up looking like it has 0 exceptions
    noExceptionDialogShown.value = true;
    setTimeout(() => {
      noExceptionDialogShown.value = false;
    }, 2000);

    await nextTick(() => {
      nextTick(() => {
        const documentView = {
          id: documentFamily.id,
          viewType: "document",
          documentFamilyId: documentFamily.id,
          ephemeral: true,
        } as DocumentViewer;
        log.info("check the documentView", documentView);
        if (sidecarStore) {
          sidecarStore.setTitle(documentFamily.path);
          sidecarStore.setSidecarView(documentView);
        }
      });
    });
  } catch (e) {
    log.error(e);
  }
}

async function rejectData() {
  if (isButtonPressed.value) {
    return;
  }

  isButtonPressed.value = true;

  try {
    log.info("Data rejected");
    if (kioskView.value) {
      const dataViewStore = createDataFormViewerStore(kioskView.value.id);
      const billDataObject = dataViewStore.dataObjects.find((d: DataObject) => d.path === "Bill");

      if (billDataObject) {
        addVSAttributes(billDataObject);
      }
    }

    await appStore.workspaceStore.addLabel("VS_REJECTED");
    await appStore.workspaceStore.saveWorkspaceObjects();

    notify({
      title: "Rejected",
      message: "The data has been rejected",
      group: "generic",
      duration: 2000,
    });

    // need this to clear path filter upon save
    pathFilter.value = "";
  } catch (e) {
    log.error(e);
    nextDoc();
  } finally {
    isButtonPressed.value = false;
  }
}

watch([isLoaded, initialized, currentWorkspaceId], async () => {
  log.info("Loaded!");
  if (!initialized.value) {
    log.info("Not initialized");
    return;
  }
  if (!sidecarPanelOpen.value) {
    toggleSidecar();
  }
  if (!isLoaded.value) {
    log.info("Not loaded");
    return;
  }
  if (!currentWorkspaceId.value) {
    loading.value = false;
    log.info("No workspace");

    // We need to load the workspace to match the kiosk
    await appStore.workspaceStore.loadWorkspace(props.kioskOptions.workspaceId);
    loading.value = true;
    return;
  }
  await loadNextDoc();
}, { immediate: true });

function toggleSidecar() {
  appStore.workspaceStore.toggleSidecar();
}

watch(sidecarPanelOpen, (value) => {
  if (value) {
    if (panes.value && panes.value[1].size === "0%") {
      panes.value[1].size = "40%";
    }
  } else {
    if (panes.value) {
      panes.value[1].size = "0%";
    }
  }
});

function nextDoc() {
  if (kioskView.value) {
    previousDocumentIds.push(kioskView.value.documentFamilyIds[0]);
    loadNextDoc();
  } else {
    log.info("No document loaded");
  }
}

function addVSAttributes(billDataObject?: DataObject) {
  // We want to set the user and timestamp on the Bill data object
  if (!kioskView.value) {
    return;
  }

  // TODO not hardcode this taxons
  const operatorTaxon = tagMetadataMap.value.get("Bill/VSOperatorID");
  const vsTimestampTaxon = tagMetadataMap.value.get("Bill/VSReviewTimeStamp");
  if (!operatorTaxon && !vsTimestampTaxon) {
    return;
  }
  appStore.workspaceStore.addAttributeToDataObject(billDataObject, operatorTaxon.taxon, appStore.userStore.user?.email);
  appStore.workspaceStore.addAttributeToDataObject(billDataObject, vsTimestampTaxon.taxon, new Date().toISOString());
}

async function saveData() {
  isButtonPressed.value = true;
  try {
    if (kioskView.value) {
      const dataViewStore = createDataFormViewerStore(kioskView.value.id);
      const billDataObject = dataViewStore.dataObjects.find((d: DataObject) => d.path === "Bill");

      addVSAttributes(billDataObject);

      // we want to add the label "VS_VERIFIED" to the document
      appStore.workspaceStore.addLabel("VS_VERIFIED");

      // Get remaining data exceptions, if no data exceptions found ; remove has_issue label
      if (allExceptions.value.length === 0) {
        // Set all 'Issues' attribute to false
        for (const attribute of billDataObject?.attributes || []) {
          if (attribute.tag.includes("Issues")) {
            attribute.booleanValue = false;
            appStore.workspaceStore.updateAttribute(billDataObject, attribute);
          }
        }
        appStore.workspaceStore.removeLabel("HAS_ISSUES");
      }
      await appStore.workspaceStore.saveWorkspaceObjects();

      notify({
        title: "Saved",
        message: "The data has been saved",
        group: "generic",
        duration: 2000,
      });

      // need this to clear barcode filter upon save
      pathFilter.value = "";

      nextDoc();
    } else {
      log.info("No document loaded");
    }
  } catch (e) {
    log.error(e);
  }
  isButtonPressed.value = false;
}

/**
 * Add a way to handle data exceptions changed. We need to factor as well
 * exceptions that has open = false which means the exceptions were override
 * @param dataExceptions
 */
function handleDataExceptionChanged(dataExceptions: DataException[]) {
  // Remove the exceptions that has open = false
  allExceptions.value = dataExceptions.filter(exception => exception.open);
}
</script>

<template>
  <div class="min-h-full">
    <main class="min-w-0 flex-1">
      <DndProvider>
        <Disclosure as="nav" class="border-b border-gray-200 bg-white">
          <div class="max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="flex h-16 justify-between">
              <div class="flex">
                <div class="flex shrink-0 items-center">
                  <img
                    :src="imageUrl"
                    alt="Kodexa" class="block h-8 w-auto"
                  >
                </div>
                <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8 selectable">
                  <span class="inline-flex items-center px-1 pt-1 text-lg">{{ currentDataForm.name }} - {{
                    currentPath
                  }}</span>
                </div>
              </div>
              <!-- Alert for system maintenance -->
              <div v-if="currentDataForm?.announcements" class="flex items-center text-red-500">
                <MaterialDesignIcon
                  aria-hidden="true"
                  class="flex size-6 shrink-0 items-center"
                  name="alert"
                />
                <span>
                  {{ currentDataForm.announcements }}
                </span>
              </div>
              <div class="ml-6 flex grow-0 items-center justify-end">
                <button
                  v-if="currentWorkspaceId"
                  class="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none"
                  type="button"
                  @click="toggleSidecar"
                >
                  <span class="sr-only">View notifications</span>
                </button>
              </div>
            </div>
          </div>
        </Disclosure>
        <div>
          <main>
            <Splitter
              v-if="panes"
              :panes="panes"
              orientation="horizontal"
              style="width: 100%"
              @change="onSplitterChange"
            >
              <template #form>
                <div v-if="loading" class="mt-14 flex justify-center">
                  <KodexaLoader />
                </div>
                <div v-else-if="!loading && !kioskView" class="mt-14 flex flex-col items-center text-center">
                  <MaterialDesignIcon class="text-red-600" name="minusCircle" size="42" />
                  <div v-html="promptMessage.formMessage" />
                </div>

                <div v-else-if="!(loading || !kioskView)" class="mx-auto" style="font-size: .9em">
                  <Toolbar class="sticky top-0 z-10 my-1 h-16 border-0 bg-white">
                    <KodexaButton
                      v-if="hasMoreDocuments"
                      id="kiosk-save"
                      :disabled="isButtonPressed || allExceptions.length > 0"
                      :togglable="false"
                      icon="content-save"
                      name="save"
                      size="small"
                      title="Save Data"
                      width="160px"
                      @click="saveData"
                    >
                      Approve & Next
                    </KodexaButton>
                    <KodexaButton
                      v-if="hasMoreDocuments"
                      :disabled="isButtonPressed"
                      :togglable="false"
                      icon="delete"
                      name="delete"
                      size="small"
                      title="Reject"
                      type="danger"
                      @click="rejectData"
                    >
                      Reject
                    </KodexaButton>
                    <KodexaButton
                      v-if="!hasMoreDocuments"
                      :disabled="isButtonPressed"
                      :togglable="false"
                      icon="refresh"
                      name="next"
                      size="small"
                      title="Refresh"
                      @click="nextDoc"
                    >
                      Refresh
                    </KodexaButton>
                    <div class="me-0 ms-auto flex">
                      <div class="grow">
                        <KodexaTextInput
                          id="pathFilter"
                          v-model="pathFilter" class="mr-4"
                          name="pathFilter" placeholder="Enter filename" size="small"
                          @keyup.enter="loadNextDoc"
                        />
                      </div>
                    </div>
                    <KodexaButton
                      icon="folder-open" name="folder-open" size="small" title="Load"
                      type="secondary"
                      @click="loadNextDoc"
                    >
                      Load
                    </KodexaButton>
                    <KodexaButton icon="invoice-text-arrow-left-outline" name="open-sidecar" size="small" @click="toggleSidecar">
                      Open Sidecar
                    </KodexaButton>
                  </Toolbar>
                  <KodexaDataFormView
                    v-if="kioskView && kioskView.viewType === 'dataForm' && currentWorkspaceId"
                    :kiosk-mode="true" :view-id="kioskView.id"
                    @data-exceptions-changed="handleDataExceptionChanged"
                    @form-saved="nextDoc"
                  />
                </div>
              </template>
              <template #sidecar>
                <SidecarPanel v-if="sidecarPanelOpen && initialized" :kiosk-mode="true" @closed="toggleSidecar" />
              </template>
            </Splitter>
          </main>
        </div>
      </DndProvider>
    </main>
    <KodexaKeyboardShortcuts />
  </div>
</template>

<style scoped>
.selectable {
  user-select: text !important;
}
</style>
