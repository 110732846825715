<script lang="ts" setup>
import { flip, offset, shift, useFloating } from "@floating-ui/vue";
import { storeToRefs } from "pinia";
import { computed, type PropType, ref, toRefs } from "vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import type { AttributeEditorOptions } from "~/components/dataObject/attribute-editor-options";
import KodexaConfirm from "~/components/kodexa-confirm.vue";
import { formatAggregationByTaxonType } from "~/components/util/attribute-utils";
import type { DataObject } from "~/model";
import appStore from "~/store";

const props = defineProps({
  field: {
    type: String,
    required: true,
  },
  dataItem: {
    type: Object,
    required: true,
  },
  columnIndex: {
    type: Number,
    required: true,
  },
  columnsCount: {
    type: Number,
    required: true,
  },
  rowType: {
    type: String,
    required: true,
  },
  level: {
    type: Number,
    required: true,
  },
  className: {
    type: String,
    required: true,
  },
  style: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  colSpan: {
    type: Number,
    required: false,
    default: 1,
  },
  expanded: {
    type: Boolean,
    required: true,
  },
  editorOptions: {
    type: Object as PropType<AttributeEditorOptions>,
    required: true,
  },
  viewId: {
    type: String as PropType<string>,
    required: false,
    default: undefined,
  },
});

const emit = defineEmits(["click"]);

const { tagMetadataMap } = storeToRefs(appStore.projectStore);
const { user } = storeToRefs(appStore.userStore);

function nestedValue(fieldName: string, dataItem: any) {
  const path = fieldName.split(".");
  let data = dataItem;
  path.forEach((p) => {
    data = data ? data[p] : undefined;
  });

  return data;
}

const { field, dataItem, columnIndex, columnsCount, rowType, level, expanded } = toRefs(props);

const renderArrow = computed(() => {
  const returnValue
    = columnIndex.value === undefined
    || level.value === undefined
    || columnIndex.value < level.value
    || columnsCount.value === undefined
    || rowType.value !== "groupHeader"
    || (dataItem.value && field.value && dataItem.value[field.value] === undefined);
  return returnValue && dataItem.value[field.value];
});

const renderCell = computed(() => {
  return field.value !== undefined && rowType.value !== "groupHeader";
});

const renderAggregates = computed(() => {
  if (field.value in dataItem.value.aggregates) {
    const aggregate = dataItem.value.aggregates[field.value];
    const taxonMeta = tagMetadataMap.value.get(field.value);
    return formatAggregationByTaxonType(aggregate, taxonMeta.taxon);
  }
  return "";
});

function onClick(e: MouseEvent) {
  emit("click", e, props.dataItem, props.expanded);
}

function getHeader() {
  return dataItem.value.value;
}

const { dataObjects } = storeToRefs(appStore.workspaceStore);

// We need to make sure if we have something in the workspace
// we are using that
const finalDataObject = computed(() => {
  return dataObjects.value.has(props.dataItem.dataObjectInstance.uuid)
    ? dataObjects.value.get(props.dataItem.dataObjectInstance.uuid)
    : props.dataItem.dataObjectInstance;
});

async function removeFromWorkspace(dataObject: DataObject) {
  const confirmLostChanges = createConfirmDialog(KodexaConfirm);
  const result = await confirmLostChanges.reveal({
    icon: "alert-circle-outline",
    title: "Remove from Workspace",
    message: "Are you sure you want to remove this data from the workspace.",
    notes: "Any changes to this data will be lost, if you wish to keep the data cancel the remove and save the workspace",
    confirmText: "Remove from Workspace",
    confirmIcon: "alert-circle-outline",
    cancelText: "Cancel",
    cancelIcon: "close",
    type: "danger",
  });

  if (result.isCanceled) {
    return false;
  }
  appStore.workspaceStore.removeDocumentFamily(dataObject.documentFamily);
}

const reference = ref(null);
const floating = ref(null);
const isHovering = ref(false);

const { floatingStyles } = useFloating(reference, floating, {
  placement: "bottom",
  middleware: [shift(), offset(8), flip()],
});
</script>

<template>
  <td v-if="rowType === 'groupFooter'">
    <div class="pr-8 flex justify-end">
      {{ renderAggregates }}
    </div>
  </td>
  <td v-else-if="renderCell" :class="className" :colspan="props.colSpan" :style="props.style">
    <div class="flex flex-col">
      <div v-if="field === 'expanded' && finalDataObject.dataExceptions?.length > 0" class="relative">
        <MaterialDesignIcon
          ref="reference"
          name="alert-circle"
          color="red"
          class="-ml-2"
          size="16"
          @mouseenter="isHovering = true"
          @mouseleave="isHovering = false"
        />
        <div
          v-if="isHovering && finalDataObject.dataExceptions?.length > 0"
          ref="floating"
          :style="floatingStyles"
          class="absolute left-0 top-0 z-50"
        >
          <div class="bg-white shadow sm:rounded-lg text-left" style="width: 500px; min-height: 200px; max-height: 200px; overflow-y: auto">
            <div class="p-3">
              <div v-for="exception in finalDataObject.dataExceptions" :key="exception.uuid">
                <h2 class="font-semibold text-lg text-gray-900">
                  {{ exception.message }}
                </h2>
                <h4 v-if="user.showDeveloperTools" class="font-normal text-xs text-gray-600 mt-1">
                  UUID: {{ exception.uuid }}
                </h4>
                <div class="mt-2 max-w-xl text-sm text-gray-500">
                  <KodexaMarkdown :content="exception.exceptionDetails" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MaterialDesignIcon
        v-if="field === 'expanded' && !finalDataObject.parentId"
        v-tooltip="'Remove from workspace'"
        name="close"
        size="16"
        class="-ml-2"
        @click="removeFromWorkspace(finalDataObject)"
      />
      <MaterialDesignIcon
        v-if="field === 'expanded'"
        :name="expanded ? 'chevron-down' : 'chevron-right'"
        size="16"
        class="-ml-2"
        @click="onClick"
      />
    </div>
    <div v-if="tagMetadataMap.has(props.field) && props.dataItem?.items">
      <KodexaDataAttributeEditor
        v-for="item in props.dataItem.items" :key="`${item.dataObjectInstance.uuid}-${props.field}`"
        :view-id="viewId"
        :tag-metadata="tagMetadataMap.get(props.field)"
        :data-object="item.dataObjectInstance"
        :editor-options="editorOptions"
      />
    </div>
    <div v-else-if="tagMetadataMap.has(props.field)">
      <KodexaDataAttributeEditor
        :view-id="viewId"
        :tag-metadata="tagMetadataMap.get(props.field)"
        :data-object="finalDataObject"
        :editor-options="editorOptions"
      />
    </div>
  </td>
  <td
    v-else-if="rowType === 'groupHeader' && columnIndex <= level"
    key="g-colspan"
    :colSpan="columnsCount - columnIndex"
  >
    <p class="k-reset">
      <a
        tabIndex="-1"
        href="#"
        :class="expanded ? 'k-i-collapse k-icon' : 'k-i-expand k-icon'"
        @click="onClick"
      />
      {{ getHeader() }}
    </p>
  </td>
  <td v-else-if="renderArrow" :key="`g${columnIndex}`" class="k-group-cell" />
  <td v-else-if="rowType !== 'groupHeader'" :class="className">
    {{ nestedValue(field, dataItem) }}
  </td>
</template>
